<template>
  <div class="px-0 lg:px-20 mt-5 mb-5">
    <div class="min-h-full bg-white">
      <!--<auto-suggestions type="textarea" placeholder="Entrer la description"></auto-suggestions>-->
      <ais-instant-search
        index-name="dokma_dev_drugs"
        v-bind:search-client="searchClient"
      >
        <ais-autocomplete>
          <div slot-scope="{ currentRefinement, indices, refine }">
            <form class="w-full">
              <div class="relative text-gray-600 w-full">
                <span
                  class="absolute left-0 h-full flex items-center justify-center ml-3"
                  ><i class="fas fa-search text-gray-500 text-2xl"></i
                ></span>
                <input
                  type="search"
                  :value="currentRefinement"
                  @input="refine($event.currentTarget.value)"
                  @click="currentRefinement = ''"
                  placeholder="Recherchez la posologie des médicaments par nom, famille ou composition"
                  class="rounded-t-cu bg-white pl-12 text-base focus:outline-none w-full py-5 border-0 border-r border-l border-t border-solid border-gray-300"
                />
              </div>
            </form>
            <table class="border border-solid border-gray-400 w-full">
              <thead class="bg-dokBlue-ultra text-white items-center">
                <tr>
                  <th class="text-lg py-5">Nom</th>
                  <th class="text-lg py-5">Composition</th>
                  <th class="text-lg py-5 hidden lg:table-cell">Famille</th>
                  <th class="text-lg py-5 hidden lg:table-cell">Fabriquant</th>
                  <th class="text-lg py-5 hidden md:table-cell">Code Atc</th>
                  <th class="text-lg py-5 hidden md:table-cell">Status</th>
                  <th class="text-lg py-5 hidden md:table-cell">Remboursser</th>
                  <th class="text-lg py-5">PPV</th>
                  <th class="text-lg py-5">Prix</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(data, i) in indices[0].hits"
                  :key="i"
                  class="border-0 border-b border-solid border-gray-400 hover:bg-gray-100 cursor-pointer' : ''"
                >
                  <th class="text-base font-EffraR py-5">{{ data.name }}</th>
                  <th class="text-base font-EffraL py-5">
                    {{ data.composition ? data.composition.join(", ") : "--" }}
                  </th>
                  <th class="text-base font-EffraL py-5 hidden lg:table-cell">
                    {{ data.famille || "--" }}
                  </th>
                  <th class="text-base font-EffraL py-5 hidden lg:table-cell">
                    {{ data.distributeur || "--" }}
                  </th>
                  <th class="text-base font-EffraL py-5 hidden md:table-cell">
                    {{ data.atc || "--" }}
                  </th>
                  <th class="text-base font-EffraL py-5 hidden md:table-cell">
                    {{ data.statut || "--" }}
                  </th>
                  <th
                    class="text-base font-EffraL py-5 hidden md:table-cell text-center"
                  >
                    {{ data.rembourssement || "--" }}
                  </th>
                  <th class="text-base font-EffraL py-5">
                    {{ data.ppv || "--" }}
                  </th>
                  <th class="text-base font-EffraL py-5">
                    {{ data.prix_hospitalier || "--" }}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </ais-autocomplete>
      </ais-instant-search>
    </div>
  </div>
</template>
<script>
const autoSuggestions = () =>
  import("@/views/global-components/autoSuggestions.vue");
const modal = () => import("@/views/global-components/modal.vue");
import algoliasearch from "algoliasearch/lite";
export default {
  data() {
    return {
      searchClient: algoliasearch(
        "2BJUOM1BDB",
        "7906f008c0346b78f39d051fa293387f"
      ),
      data: {}
    };
  },
  components: { modal, autoSuggestions }
};
</script>
<style lang="scss">
table {
  border-collapse: collapse;
  border-spacing: 0;
}
</style>
